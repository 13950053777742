<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="bookingTypeList.data"
            :reduce="item => item.id"
            v-model="booking_type_id"
            placeholder="Booking Type"
          > 
          </v-select>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select>
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3"> 
        </CCol>
        <CCol lg="3"> 
        </CCol>
        <CCol lg="3">
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';

    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            customer_id: "",
            booking_type_id: "",
            depot_id: "",
            customerList: {
              data: []
            },
            bookingTypeList: {
              data: []
            },
            depotList: {
              data: []
            }
          }
        },
      mounted() {
        this.getCustomerList();
        this.getBookingTypeList();
        this.getDepotList();
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            customer_id: this.customer_id,
            booking_type_id: this.booking_type_id,
            depot_id: this.depot_id,
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.customer_id = "";
          this.booking_type_id = "";
          this.depot_id = "";

          const data = {
            customer_id: this.customer_id,
            booking_type_id: this.booking_type_id,
            depot_id: this.depot_id,
          }
          this.$emit("depot-search-query", data)
        },
        updateVendorType(data){
          this.vendor_type = data;
        },
        getCustomerList(){
            axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
          }
          })
        .then(response => {
          this.customerList = response.data; 
                
        })
        }, 

        getBookingTypeList(){
          axios.get(config.api_path+'/reference/settings-list',{
            params:{
            setting_type:'booking_type'
              }
            })
          .then(response => {
          this.bookingTypeList = response.data; 
        })

     }, 

        getDepotList(){
          axios.get(config.api_path+'/reference/settings-list',{
            params:{
            setting_type:'depot'
              }
            })
          .then(response => {
          this.depotList = response.data; 
        })

     }

        
      }
    }
</script>

<style scoped>

</style>
